export const userActionTypes = {
  PROCESS_START: "PROCESS_START",
  PROCESS_FAILURE: "PROCESS_FAILURE",
  SIGN_IN: "SIGN_IN",
  REGISTER: "REGISTER",
  LOGOUT: "LOGOUT",
  GET_CURRENT_USER: "GET_CURRENT_USER",
  UPDATE_USER: "UPDATE_USER",
  CLEAR_ERROR: "CLEAR_ERROR",
};
