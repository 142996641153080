export const actionTypes = {
  PROCESS_START: "PROCESS_START",
  PROCESS_FAILURE: "PROCESS_FAILURE",
  FETCH_DATA_STRUCTURES_SUCCESS: "FETCH_DATA_STRUCTURES_SUCCESS",
  ADD_DATA_STRUCTURE_SUCCESS: "ADD_DATA_STRUCTURE_SUCCESS",
  ADD_DATA_STRUCTURE: "ADD_DATA_STRUCTURE",
  UPDATE_DATA_STRUCTURE_NAME_SUCCESS: "UPDATE_DATA_STRUCTURE_NAME_SUCCESS",
  DELETE_DATA_STRUCTURE: "DELETE_DATA_STRUCTURE",
  ADD_NODE: "ADD_NODE",
  RENAME_NODE: "RENAME_NODE",
  DELETE_NODE: "DELETE_NODE",
  ADD_CONTENT: "ADD_CONTENT",
  DELETE_CONTENT: "DELETE_CONTENT",
  UPDATE_CONTENT: "UPDATE_CONTENT",
};
